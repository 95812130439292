import { useCallback, useEffect, useState } from "react";

export const useBookings = (path: string = `${process.env.REACT_APP_BACKEND_URL}/bookings`) => {
    const [isLoading, setIsLoading] = useState(false)
    const [bookings, setBookings] = useState<any[]>([])

    const getBookings = useCallback(async (showSpinner: boolean = true) => {
        if (showSpinner) setIsLoading(true)
        const response = await fetch(path)
        const bookings = await response.json()
        if (showSpinner) setIsLoading(false)
        setBookings(bookings)
    }, [path])

    useEffect(() => {
        getBookings()
    }, [getBookings])

    return {
        isLoading,
        bookings,
        getBookings
    }
};