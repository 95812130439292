import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { useCallback, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Menu } from "primereact/menu";
import { Skeleton } from "primereact/skeleton";
import * as moment from "moment-timezone"
import { Tooltip } from "primereact/tooltip";
import { useSearchParams } from "react-router-dom";

import './ReservationsTable.css'
import { getFriendlyChannelName } from "../../utils/channel";

export const ReservationsTable = ({
    data,
    codes,
    multiFamily = true,
    loading = false }:
    {
        data: any,
        codes?: any,
        multiFamily?: boolean,
        loading?: boolean
    }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const menu = useRef<Menu>(null);
    const [selectedRow, setSelectedRow] = useState();
    const [loadingSpinnerId, setLoadingSpinnerId] = useState(0);
    const toast = useRef<Toast>(null);

    const tableStyle = () => ({ minWidth: '50rem' })

    const today = useMemo(() => {
        let date = new Date();
        date.setHours(date.getHours() - 7);
        return date.toISOString().split('T')[0]
    }, [])

    const formatDate = (date: string) => {
        return moment.tz(date, 'America/Los_Angeles').format('ddd M/DD')
    }

    const formatTime = (date: string) => {
        return moment.tz(date, 'America/Los_Angeles').format('h:mm A')
    }

    const isEarlyCheckIn = (time: string) => {
        return time !== '16:00:00'
    }

    const isLateCheckOut = (time: string) => {
        return time !== '11:00:00'
    }

    const renderLock = (res: any) => {
        if (codes?.length === 0) return (<Skeleton />)

        const code = getLockCode(res);
        if (!code) return (<Tag value={`Missing`} severity={`danger`} />);

        if (isLockExpired(code)) {
            return (<Tag value={`Expired`} severity={`info`} className="tag-tooltip grey" />)
        }

        const isValid = isLockValid(code, res);
        return (isValid ? <Tag value={`${code.keyboardPwd}`} severity={`success`} /> :
            <>
                <Tooltip target=".tag-tooltip" />
                <Tag value={`${code.keyboardPwd}`}
                    severity={`warning`}
                    className="tag-tooltip"
                    data-pr-tooltip={formatValidLockTimes(code)}
                    data-pr-position="bottom" />
            </>
        );
    }

    const isLockValid = (code: any, res: any): boolean => {
        if (!res || !code) return false;
        const resStartTime = moment.utc(res.check_in);
        const resEndTime = moment.utc(res.check_out);
        const codeStartTime = moment.tz(code.startDate, 'YYYY-MM-DD hh:mm a', 'America/Los_Angeles');
        const codeEndTime = moment.tz(code.endDate, 'YYYY-MM-DD hh:mm a', 'America/Los_Angeles');
        const isValid = resStartTime.format('YYYY-MM-DD HH:mm') === codeStartTime.format('YYYY-MM-DD HH:mm') && resEndTime.format('YYYY-MM-DD HH:mm') === codeEndTime.format('YYYY-MM-DD HH:mm')

        return isValid;
    }

    const isLockExpired = (code: any): boolean => {
        if (!code) return false;
        const codeEndTime = moment.tz(code.endDate, 'YYYY-MM-DD hh:mm a', 'America/Los_Angeles');
        const currentTime = moment.tz('America/Los_Angeles');
        const isExpired = currentTime > codeEndTime

        return isExpired;
    }

    const formatValidLockTimes = (code: any): string => {
        const codeStartTime = moment.tz(code.startDate, 'YYYY-MM-DD hh:mm a', 'America/Los_Angeles');
        const codeEndTime = moment.tz(code.endDate, 'YYYY-MM-DD hh:mm a', 'America/Los_Angeles');
        return `${codeStartTime.format('MM/DD | h:mm a')} \n ${codeEndTime.format('MM/DD | h:mm a')}`
    }

    const getLockCode = useCallback((res: any) => {
        if (!res) return;
        return codes?.find((c: { keyboardPwdId: any; }) => Number(res.keyboardPwdId) === c.keyboardPwdId);
    }, [codes])

    const guestName = (res: any) => {
        const onClick = (e: any) => {
            searchParams.set('reservation', res.id);
            setSearchParams(searchParams.toString());
        }
        return <div className="link" onClick={onClick}>{res.guest_name}</div>
    }

    const Checkin = (row: any) => {
        return (<div className={row.check_in.split('T')[0] === today ? 'isToday' : ''}>{formatDate(row.check_in)}</div>)
    }

    const isCheckoutToday = (row: any) => {
        return (<div className={row.check_out.split('T')[0] === today ? 'isToday' : ''}>{formatDate(row.check_out)}</div>)
    }

    const CheckinTime = (row: any) => {
        return (
            <div className="flex flex-row flex-align-center">
                {formatTime(row.check_in)} {isEarlyCheckIn(row.arrival_time) ? <Tag className="small-tag" value={`EARLY`} severity={`warning`}></Tag> : ""}
            </div>
        )
    }

    const CheckoutTime = (row: any) => {
        return (
            <div className="flex flex-row flex-align-center">
                {formatTime(row.check_out)}
                {row.guest_checkout === "true" ? <Tag className="small-tag" value={`✓`} severity={`info`}></Tag> : ""}
                {isLateCheckOut(row.departure_time) ? <Tag className="small-tag" value={`LATE`} severity={`warning`}></Tag> : ""}
            </div>
        )
    }

    const setCode = useCallback(async (row: any, action: string) => {
        setLoadingSpinnerId(row.id)
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/locks?action=${action}`, {
            method: 'POST',
            body: JSON.stringify(row)
        })
            .then(response => {
                if (response.status !== 200) { return Promise.reject(response) }
                return response.json();
            })
            .then(data => {
                if(!data.errcode || data.errcode === 0) {
                    row.keyboardPwdId = data.keyboardPwdId
                    const lockCode = getLockCode(row)
                    if (lockCode) {
                        lockCode.startDate = data.startDate;
                        lockCode.endDate = data.endDate;
                    } else {
                        codes.push(data)
                    }
                } else {
                    toast.current?.show({ severity: 'error', summary: `Failed to ${action}`, detail: data.errmsg });
                    console.log(data)
                }
            })
            .catch((response) => {
                response.json().then((json: any) => {
                    toast.current?.show({ severity: 'error', summary: `Failed to ${action}`, detail: json.errmsg });
                    console.log(response)
                })
            })
            .finally(() => {
                setLoadingSpinnerId(0)
            })

    }, [codes, getLockCode])


    const createTimedCode = useCallback(async (row: any) => {
        setLoadingSpinnerId(row.id)
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/locks?action=timed`, {
            method: 'POST',
            body: JSON.stringify(row)
        })
            .then(response => {
                if (response.status !== 200) { return Promise.reject(response) }
                return response.json();
            })
            .then(data => {
                row.keyboardPwdId = data.keyboardPwdId
                codes.push(data)
            })
            .catch((response) => {
                response.json().then((json: any) => {
                    toast.current?.show({ severity: 'error', summary: `Failed to create timed code`, detail: json.errmsg });
                    console.log(response)
                })
            })
            .finally(() => {
                setLoadingSpinnerId(0)
            })
    }, [codes])

    const actionOptions = useMemo(() => {
        const lockCode = getLockCode(selectedRow);
        return [
            {
                label: 'Lock Codes',
                items: [
                    {
                        label: 'Add Code',
                        command: () => setCode(selectedRow, `addCode`),
                        disabled: !(!lockCode || (lockCode.keyboardPwd.length > 4 && !isLockExpired(lockCode))) // Lock Code is missing or Timed Code and not code expired
                    },
                    {
                        label: 'Update Code',
                        command: () => setCode(selectedRow, `updateCode`),
                        disabled: !(lockCode && !isLockValid(lockCode, selectedRow) && lockCode.keyboardPwd.length === 4 && !isLockExpired(lockCode)) // Lock Code is not missing and is invalid and is not timed code
                    },
                    {
                        label: 'Create Timed Code',
                        command: () => createTimedCode(selectedRow),
                        disabled: !(!isLockExpired(lockCode))
                    }
                ]
            },
        ]
    }, [setCode, createTimedCode, selectedRow, getLockCode])

    const renderActions = (row: any) => {
        return (
            <div className="flex flex-wrap gap-3">
                <Button loading={loadingSpinnerId === row.id} icon="pi pi-bars" severity="secondary" text onClick={(event) => { setSelectedRow(row); menu.current?.toggle(event) }} />
            </div>
        )
    }

    const channelName = (row: any) => getFriendlyChannelName(row.channel)

    return (
        <>
            <Menu model={actionOptions} popup ref={menu} id="actions_menu" />
            <Toast ref={toast} />
            <DataTable loading={loading} value={data} tableStyle={tableStyle()}>
                {multiFamily && <Column field="unit" filter header="Unit"></Column>}
                <Column header="Name" style={{ minWidth: '200px' }} body={guestName}></Column>
                <Column header="Check In" field="check_in" sortable body={Checkin}></Column>
                <Column header="Check Out" field="check_out" sortable body={isCheckoutToday}></Column>
                <Column header="Channel" body={channelName}></Column>
                <Column field="number_of_guests" header="# of Guest"></Column>
                {multiFamily && <Column header="Arrival Time" body={CheckinTime}></Column>}
                {multiFamily && <Column header="Departure Time" body={CheckoutTime}></Column>}
                {multiFamily && <Column header="Lock" body={renderLock}></Column>}
                {multiFamily && <Column header="Actions" body={renderActions}></Column>}
            </DataTable>
        </>
    )
}
