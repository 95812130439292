import { useCallback, useEffect, useState } from "react";

export const useCleaning = (path: string) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reservations, setReservations] = useState<any[]>([])

    const getReservations = useCallback(async (showSpinner: boolean = true) => {
        if (showSpinner) setIsLoading(true)
        const response = await fetch(path)
        const reservations = await response.json()
        if (showSpinner) setIsLoading(false)
        setReservations(reservations)
    }, [path])

    useEffect(() => {
        getReservations()
    }, [getReservations])

    return {
        isLoading,
        reservations,
        getReservations
    }
};