import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import { Menu } from 'primereact/menu';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Tooltip } from "primereact/tooltip";

import './cleanings.css'
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCleaning } from '../../hooks/useCleaning';
import { SFHNames } from '../../constants';
import { getFriendlyChannelName } from '../../utils/channel';


interface Reservation {
  id: number;
  guest_name: string;
  channel: string;
  unit: string;
  check_in: string;
  check_out: string;
  arrival_time: string;
  departure_time: string;
  clean_status: string;
  cleaner: string;
  guest_checkout: string;
}

export function Cleanings() {
  const menu2 = useRef<Menu>(null);
  const [tempResId, setTempResId] = useState<number>()
  const [date, setDate] = useState<any>(new Date());
  const [isUpdating, setIsUpdating] = useState<number | undefined>(undefined)
  const { reservations: cleanings, getReservations: getCleanings } = useCleaning(`${process.env.REACT_APP_BACKEND_URL}/bookings?from=${date.toLocaleDateString('af')}&to=${date.toLocaleDateString('af')}`)

  useEffect(() => {
    getCleanings();
  }, [getCleanings, date])

  const updateCleaning = useCallback(async (id: number, body: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dynamo`, {
        method: 'POST',
        body: JSON.stringify({ id, ...body })
      })
      return response.json()
    } catch (err) {
      console.log(err)
    }
  }, [])

  const updateReservation = useCallback(async (id: number, body: any) => {
    setIsUpdating(id)
    await updateCleaning(id, body);
    await getCleanings();
    setIsUpdating(undefined)
  }, [updateCleaning, getCleanings])

  const handleUpdateRes = useCallback((attr: any) => {
    if (tempResId) {
      updateReservation(tempResId, attr);
    }
  }, [tempResId, updateReservation])

  const actionOptions = useMemo(() => {
    return [
      {
        label: 'Status',
        items: [
          {
            label: 'Clean',
            command: () => handleUpdateRes({ clean_status: 'CLEAN' })
          },
          {
            label: 'Not Clean',
            command: () => handleUpdateRes({ clean_status: 'NOT CLEAN' })
          }
        ]
      },
      {
        label: 'Cleaner',
        items: [
          {
            label: 'KATIE',
            command: () => handleUpdateRes({ cleaner: 'KATIE' })
          },
          {
            label: 'CHRISTINA',
            command: () => handleUpdateRes({ cleaner: 'CHRISTINA' })
          },
          {
            label: 'ELINA',
            command: () => handleUpdateRes({ cleaner: 'ELINA' })
          },
          {
            label: 'SHERRY',
            command: () => handleUpdateRes({ cleaner: 'SHERRY' })
          },
          {
            label: 'TRACY',
            command: () => handleUpdateRes({ cleaner: 'TRACY' })
          },
          {
          label: 'KAYLA',
            command: () => handleUpdateRes({ cleaner: 'KAYLA' })
          },
        ]
      }
    ]
  }, [handleUpdateRes])

  const defineMenu = () => {
    return (<div>
      <Menu model={actionOptions} popup ref={menu2} id="actions_menu" />
    </div>
    )
  }

  const getCleanSeverity = (res: Reservation) => {
    switch (res.clean_status) {
      case 'CLEAN':
        return 'success';
      case 'NOT CLEAN':
        return 'danger';
      default:
        return null;
    }
  };

  const getCabinNumber = (reservation: Reservation) => {
    return reservation.unit
  }

  const cleanerStatusOnClick = useCallback((res: Reservation, event: any) => {
    menu2.current?.toggle(event)
    setTempResId(res.id)
  }, [menu2])

  const itemTemplate = useCallback((res: Reservation) => {
    return (
      <div className="row gap-3" >
        <div className="status ">
          <div className="text-4xl font-semibold">{getCabinNumber(res)}</div>
          <Tag value={res.clean_status} severity={getCleanSeverity(res)}></Tag>
        </div>

        <div className="info gap-2">
          <div className='flex flex-row gap-2'>
            <div className="text-1xl text-900">{res.guest_name}</div>
            <Tooltip target=".channel-tooltip" />
            <Tag className="small-tag text-slate-950 bg-gray-500	 channel-tooltip" data-pr-position="bottom"
              data-pr-tooltip={getFriendlyChannelName(res.channel)}
              value={getFriendlyChannelName(res.channel)[0].toUpperCase()} />
          </div>
          <div className="text-1xl text-900">{res.departure_time.slice(0, 5)}
            {res.departure_time.slice(0, 5) !== `11:00` ? <Tag className="small-tag" value={`LATE`} severity={`warning`}></Tag> : ""}
            {res.guest_checkout === 'true' ? <Tag className="small-tag" value={`CHECKED OUT`} severity={`info`}></Tag> : ""}
          </div>
          <div className="text-1xl text-900 font-bold">{res.cleaner || 'Katie'}</div>
        </div>

        <div className="actions">
          {
            isUpdating !== res.id
              ? <Button icon="pi pi-bars" severity="secondary" rounded text onClick={(event) => cleanerStatusOnClick(res, event)} aria-controls="cleaner_update_menu" aria-haspopup />
              : <ProgressSpinner style={{ width: '50px', height: '50px' }} />
          }
        </div>
      </div>
    );
  }, [cleanerStatusOnClick, isUpdating]);

  return (
    <div className="card">
      {defineMenu()}
      <div className="card flex justify-content-center">
        <Button icon="pi pi-chevron-left" rounded text onClick={(e) => { setDate(new Date(date.setDate(date.getDate() - 1))) }} />
        <Calendar inputClassName="cal" value={date} onChange={(e: CalendarChangeEvent) => { setDate(e.value) }} showButtonBar />
        <Button icon="pi pi-chevron-right" rounded text onClick={(e) => { setDate(new Date(date.setDate(date.getDate() + 1))) }} />
      </div>
      <DataView
        value={cleanings.filter(x => x.check_out.split('T')[0] === date.toLocaleDateString('af') && !SFHNames.has(x.unit))}
        itemTemplate={itemTemplate} />
    </div>
  )
}