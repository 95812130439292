
export const kcmo_team = ['george@yourcleaningmadesimple.com']
export const bigbear_cleaner = ['katie.devien@gmail.com']
export const admins = ['reid@wander-collective.com', 'allen@wander-collective.com']
export const bigbear_team = ['katie.devien@gmail.com', 'cris7i7uv82@gmail.com', 'skinnerkayla@outlook.com']
export const internal_team = [...admins, 'ashlee@wander-collective.com']
export const SFHNames = new Set([
    'Heartland Haven',
    'Luxury house | Downtown',
    'Cozy Cactus'
])

export const bookingHotelIdMap = new Map<number, string>([
    [67868, '10667838'], // OLD LF1
    [67867, '10667838'], // OLD LF2
    [68728, '10667838'], // OLD 59
    [85430, '10667838'], // LF1
    [85431, '10667838'], // LF2
    [84670, '10667838'], // 59
    [67869, '10677474'], // OLD Village Cabins
    [79001, '10677474'], // OLD Village Cabins w/ Spa,
    [81566, '10677474'], // Village Cabins
    [81565, '10677474'], // Village Cabins w/ Spa,
    [71899, '10694575'], // 10
    [71895, '10694575'], // 10C
    [71896, '10694575'], // 18A
    [71900, '10694575'], // 7A
    [71897, '10694575'], // 7B
    [77188, '10694575'], // 7C
    [71898, '10694575'], // 33
    [74668, '10694575'], // 10B
    [75706, '10694575'], // 10A
    [95164, '12389008'], // 21
    [95206, '12389545'], // 22
    [95163, '12393466'], // 23
    [95166, '12393778'], // 24
    [96929, '12394729'], // 25
    [95165, '12395000'], // 26

]);