import { useCallback, useEffect, useState } from "react";

export const useBilling = (dates: Date[], path: string = `${process.env.REACT_APP_BACKEND_URL}/billing?from=${dates[0]?.toLocaleDateString('af')}&to=${dates[1]?.toLocaleDateString('af')}`) => {
    const [isLoading, setIsLoading] = useState(false)
    const [billing, setBilling] = useState<any[]>([])

    const getBilling = useCallback(async (showSpinner: boolean = true) => {
        if (showSpinner) setIsLoading(true)
        const response = await fetch(path)
        const billing = await response.json()
        if (showSpinner) setIsLoading(false)
        setBilling(billing)
    }, [path])

    useEffect(() => {
        if (dates[0] && dates[1]) {
            getBilling()
        }
    }, [getBilling, dates])

    return {
        isLoading,
        billing,
        getBilling: getBilling
    }
};

