import { useState } from 'react';
import { Calendar } from './Calendar';
import { Cleanings } from './Cleanings';
import { Billing } from './Billing/billing';
import { PrimeReactProvider } from 'primereact/api';
import { Loader } from '../common';
import { Locks } from './Locks';
import { useAuth0 } from "@auth0/auth0-react";
import { Login } from './Login';
import { admins, bigbear_cleaner, bigbear_team, internal_team } from '../constants'
import { Menubar } from 'primereact/menubar';
import { RefundTracker } from './Refunds';
import { SearchBar } from '../Components/SearchBar/SearchBar';
import { ReservationView } from '../Components/ReservationView/ReservationView';
import { useSearchParams } from 'react-router-dom';

function Home() {
    const [searchParams] = useSearchParams();
    const [activeIndex, setActiveIndex] = useState(0);
    const { isAuthenticated, isLoading: isAuthLoading, user } = useAuth0();
    const items: any = [
        { label: 'Calendar', icon: 'pi pi-fw pi-calendar', command: () => { setActiveIndex(0) } },
        [...internal_team, ...bigbear_cleaner, ...bigbear_team].includes(user?.email!) ? { label: 'Cleaning', icon: 'pi pi-fw pi-home', command: () => { setActiveIndex(1) } } : {},
        [...admins].includes(user?.email!) ? { label: 'Locks', icon: 'pi pi-fw pi-lock', command: () => { setActiveIndex(2) } } : {},
        [...admins].includes(user?.email!) ? { label: 'Refund Tracker', icon: 'pi pi-fw pi-arrow-right-arrow-left', command: () => { setActiveIndex(3) } } : {},
        [...admins].includes(user?.email!) ? { label: 'Billing', icon: 'pi pi-fw pi-money-bill', command: () => { setActiveIndex(4) } } : {},
    ];

    function renderSwitch() {
        switch (activeIndex) {
            case 1:
                return <Cleanings />
            case 2:
                return <Locks />
            case 3:
                return <RefundTracker />
            case 4:
                return <Billing />
            case 0:
            default:
                return <Calendar />
        }
    }

    if (isAuthLoading) {
        return <Loader />
    }

    if (!isAuthenticated) {
        return <Login />
    }

    return (
        isAuthenticated && (
            <PrimeReactProvider>
                <ReservationView id={searchParams.get('reservation')} />
                <div className="card">
                    <Menubar model={items} end={SearchBar} />
                </div>
                <div>
                    {renderSwitch()}
                </div>
            </PrimeReactProvider>

        )
    )
}

export default Home;
