import { useEffect, useState } from "react";

export const useLocks = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [codes, setCodes] = useState<any[]>([])

    const getLocks = async (showSpinner: boolean = true) => {
        if (showSpinner) setIsLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locks`)
        const codes = await response.json()
        if (showSpinner) setIsLoading(false)
        setCodes(codes)
    }

    useEffect(() => {
        getLocks()
    }, [])

    return {
        isLoading,
        codes,
        getLocks
    }
};