import { InputText } from "primereact/inputtext"
import { OverlayPanel } from "primereact/overlaypanel";
import { classNames } from "primereact/utils";
import {  useEffect, useRef, useState } from "react";
import * as moment from "moment-timezone"

import './SearchBar.css'
import { useSearchParams } from "react-router-dom";

export const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchEvent, setSearchEvent] = useState<any>();
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const op = useRef<OverlayPanel>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!searchTerm) return;
            try {
                setIsLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dynamo?type=search&searchTerm=${searchTerm}`);
                const data = await response.json();
                setResults(data); 
                setIsLoading(false);
                op?.current?.show(searchEvent, searchEvent?.target)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Call the API when the searchTerm changes (debounced for better performance)
        const debounceTimer = setTimeout(() => {
            if (searchEvent?.target?.value?.trim() !== '') {
                fetchData();
            } else {
                op?.current?.hide();
                setResults([]);
            }
        }, 300);

        return () => clearTimeout(debounceTimer);

    }, [searchTerm, searchEvent]);


    const onChangeSearch = (e: any) => {
        setSearchTerm(e.target.value);
        setSearchEvent(e);
    }

    const onClickItem = (e: any, res: any) => {
        searchParams.set('reservation', res.id);
        setSearchParams(searchParams.toString());
    }

    const onHide = () => {
        setSearchTerm('');
    }

    const formatDate = (date: string): string => {
        return moment.tz(date, 'America/Los_Angeles').format('MMM Do YYYY')
    }

    const itemTemplate = (res: any, index: number) => {

        const className = classNames('result-pane-item row gap-3', {
            'surface-hover': index % 2
        });

        return (
            <div className={className} key={index} onClick={(e) => onClickItem(e, res)}>
                <div className="left-info gap-2">
                    <div className="text-lg font-bold">{res.guest_name}</div>
                    <div className="text-sm font-normal">{formatDate(res.check_in)} → {formatDate(res.check_out)}</div>
                </div>

                <div className="right-info gap-2">
                    <div className="text-base font-medium">{res.multi_unit_name || res.property_name}</div>
                    <div className="text-base font-semibold">{res.reason}</div>
                </div>
            </div>
        )
    };

    const resultsPane = (results: any) => {
        if (results.length === 0) {
            return (
                <div className="results-pane no-results">
                    <div className="text-lg font-bold text-color-secondary">No Results Found</div>
                </div>
            )
        }
        return (
            <div className="flex flex-column results-pane">
                {results.map((x: any, i: number) => itemTemplate(x, i))}
            </div>
        )
    }

    return (
        <>
            <div className="flex align-items-center gap-2">
                <span className="p-input-icon-right">
                    {isLoading ? <i className="pi pi-spin pi-spinner" /> : ""}
                    <InputText
                        placeholder="Search"
                        type="text"
                        className="w-8rem sm:w-auto"
                        value={searchTerm}
                        onChange={(e) => onChangeSearch(e)} />
                </span>

                <OverlayPanel ref={op} dismissable={true} onHide={() => onHide()}>
                    {resultsPane(results)}
                </OverlayPanel>
            </div>

        </>
    )
}