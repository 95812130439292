import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './Views/Login';
import Home from "./Views/Home";

import './App.css';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';


export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </Router >
  )
};