import { useCallback, useEffect, useMemo, useState } from 'react';
import { currencyFormater } from '../../common';
import { useBilling } from '../../hooks/useBilling';
import { Chart } from 'primereact/chart';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Skeleton } from 'primereact/skeleton';
import { BillingTable } from '../../Components/BillingTable';


import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';

import './billing.css'
import { Tooltip } from 'primereact/tooltip';

export function Billing() {
    const date = new Date();

    const [dates, setDates] = useState<any>([new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)]);
    const { billing, isLoading } = useBilling(dates)

    const sourceFilterOptions = [
        { label: "Uplisting", value: 'uplisting' },
        { label: "Airbnb", value: "airbnb_official" },
        { label: "Google", value: 'google' },
        { label: "Booking", value: 'booking_dot_com' },
        { label: "VRBO", value: 'home_away' }
    ]

    const [sourceFilter, setSourceFilter] = useState<any>(sourceFilterOptions.map(x => x.value))

    const unitFilterOptions = useMemo(() => Array.from(new Set(billing.map((x) => x.unit))).sort(), [billing])
    const [unitFilter, setUnitFilter] = useState<any[]>([])

    const accomodationTotal = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.accomodation_total), 0);
    }, [billing, sourceFilter, unitFilter])

    const taxes = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.booking_taxes), 0);
    }, [billing, sourceFilter, unitFilter])

    const cleaning = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.cleaning_fee), 0);
    }, [billing, sourceFilter, unitFilter])

    const reservations = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).length;
    }, [billing, sourceFilter, unitFilter])

    const nights = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.number_of_nights), 0);
    }, [billing, sourceFilter, unitFilter])

    const discounts = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.discounts), 0);
    }, [billing, sourceFilter, unitFilter])

    const refunds = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.refund_amount || 0), 0);
    }, [billing, sourceFilter, unitFilter])

    const commission = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.commission), 0);
    }, [billing, sourceFilter, unitFilter])

    const paymentProcessingFees = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) =>
            sum + current.payment_processing_fee ? Number(current.payment_processing_fee) : 0, 0);
    }, [billing, sourceFilter, unitFilter])

    const otherFees = useMemo(() => {
        return billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel)).reduce((sum, current) => sum
            + Number(current.extra_guest_charges)
            + Number(current.extra_charges), 0);
    }, [billing, sourceFilter, unitFilter])

    const grossRevenue = useMemo(() => {
        return accomodationTotal + cleaning + taxes + otherFees - discounts - refunds;
    }, [accomodationTotal, cleaning, taxes, otherFees, discounts, refunds])

    const costOfRevenue = useMemo(() => {
        return paymentProcessingFees + commission + taxes;
    }, [paymentProcessingFees, commission, taxes])

    const netRevenue = useMemo(() => {
        return grossRevenue - costOfRevenue;
    }, [grossRevenue, costOfRevenue])

    const billingTable = useMemo(() => {
        return (
            <div className="surface-card shadow-2 p-3 border-round">
                <div className="card ">
                    <BillingTable data={billing.filter(x => unitFilter.includes(x.unit) && sourceFilter.includes(x.channel))} />
                </div>
            </div>
    )
    }, [billing, sourceFilter, unitFilter])

    useEffect(() => {
        setUnitFilter(unitFilterOptions)
    }, [billing, unitFilterOptions])

    const getFilters = () => {
        return (
            <div className="surface-ground px-4 py-3 md:px-6 lg:px-8">
                <div className="surface-card shadow-2 p-3 border-round gap-3 col-12">
                    <div className="col-12 md:col-12 lg:col-12">
                        <div className="grid gap-3">
                            <Calendar className="w-full md:w-17rem" inputClassName='datePicker' value={dates} onChange={(e: CalendarChangeEvent) => setDates(e.value)} selectionMode="range" readOnlyInput showIcon />

                            <MultiSelect value={unitFilter} onChange={(e) => setUnitFilter(e.value)} options={unitFilterOptions} scrollHeight="400px"
                                placeholder="Select Units" className="w-full md:w-13rem" />

                            <MultiSelect value={sourceFilter} onChange={(e) => setSourceFilter(e.value)} options={sourceFilterOptions} optionLabel="label" scrollHeight="400px"
                                placeholder="Select Channel" className="w-full md:w-13rem" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Accomodation Total', 'Cleaning', 'Extra Charges', 'Tax'],
            datasets: [
                {
                    data: [accomodationTotal, cleaning, otherFees, taxes],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-300'),
                        documentStyle.getPropertyValue('--yellow-300'),
                        documentStyle.getPropertyValue('--green-300'),
                        documentStyle.getPropertyValue('--red-300')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400'),
                        documentStyle.getPropertyValue('--red-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
        // eslint-disable-next-line
    }, [billing, accomodationTotal, cleaning, otherFees, taxes]);

    // eslint-disable-next-line
    const getTotalsPieChart = () => {
        return (
            <div className="col-12 md:col-12 lg:col-12">
                <div className="surface-card shadow-2 p-3 border-round">
                    <div className="block text-500 font-medium mb-3 text-xl">Totals</div>
                    <div className="card flex justify-content-center">
                        {isLoading
                            ? <Skeleton />
                            : <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                        }
                    </div>
                </div>
            </div>
        )
    }

    const getByUnitValues = () => {
        const units = unitFilter.map(unit => {
            return {
                unit: unit,
                unitAccomodationTotal: billing.filter(x => x.unit === unit && sourceFilter.includes(x.channel)).reduce((sum, current) => sum + Number(current.accomodation_total), 0),
            }
        })

        units.sort((a, b) => b.unitAccomodationTotal - a.unitAccomodationTotal)
        const hightestTotal = units[0]?.unitAccomodationTotal | 0;
        return units.map(x => unitProgressBar(x.unit, x.unitAccomodationTotal, hightestTotal))
    }

    const unitProgressBar = (unit: string, amount: number, total: number) => {
        return (
            <li key={unit} className="mb-2">
                <div className="flex justify-content-between align-items-center">
                    <span className="text-900 inline-flex justify-content-between align-items-center">
                        <span className="font-medium text-900">Cabin {unit}</span>
                    </span>
                    <span className="text-blue-300 font-medium">{currencyFormater.format(amount)}</span>
                </div>
                <ProgressBar value={(amount / total) * 100} showValue={false} color={'#84b2f9'}></ProgressBar>
            </li>
        )
    }

    const getByUnitProgressBars = () => {
        return (
            <div className="shadow-2 surface-card border-round p-4 h-full">
                <div className="block text-500 font-medium mb-3 text-xl">Totals By Unit</div>
                <ul className="list-none p-0 m-0 progressList">
                    {isLoading ? <Skeleton /> : getByUnitValues()}
                </ul>
            </div>
        )
    }

    const statCard = useCallback((title: string, value: string, icon: string, iconColor: string, subtitle?: string, subtitleValue?: string, tooltipMessage?: string) => {
        return (
            <div className="col-12 md:col-6 lg:col-4">
                <div className="surface-card shadow-2 p-3 border-round">
                    {isLoading ? <Skeleton className='p-5'></Skeleton> : (
                        <>
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <div>
                                        {tooltipMessage && <Tooltip target=".custom-target-icon" />}
                                        <span
                                            className="custom-target-icon block text-500 font-medium mb-3"
                                            data-pr-tooltip={tooltipMessage}
                                            data-pr-position="right"
                                        >{title}</span>
                                    </div>
                                    <div className="text-900 font-medium text-xl">{value}</div>
                                </div>
                                <div className={"flex align-items-center justify-content-center bg-" + iconColor + "-100 border-round"} style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className={"pi " + icon + " text-" + iconColor + "-500 text-xl"}></i>
                                </div>
                            </div>
                            <span className="text-yellow-500 font-medium text-m">{subtitleValue} </span>
                            <span className="text-500">{subtitle}</span></>
                    )}
                </div>
            </div >
        )
    }, [isLoading])

    const dashboard = () => (
        <div className="surface-ground px-4 py-2 md:px-6 lg:px-8">
            <div className="grid nested-grid">
                <div className="col-12 md:col-6 lg:col-8">
                    <div className="grid">
                        {statCard('Gross Revenue', currencyFormater.format(grossRevenue), 'pi-dollar', 'green', '', '', 'Accomondation Total + Cleaning + Taxes + Other Fees - Discounts')}
                        {statCard('Cost of Revenue', currencyFormater.format(-costOfRevenue), 'pi-dollar', 'green', '', '', 'Processing Fees + Commission + Taxes')}
                        {statCard('Total Payout', currencyFormater.format(netRevenue), 'pi-dollar', 'green', '', '', 'Gross Revenue - Cost of Revenue')}
                        {statCard('Accomodation Total', currencyFormater.format(accomodationTotal - refunds), 'pi-wallet', 'orange', 'Discounts', currencyFormater.format(discounts))}
                        {statCard('Cleaning Total', currencyFormater.format(cleaning), 'pi-key', 'purple', 'Without Taxes', currencyFormater.format(cleaning * 0.88))}
                        {statCard('Tax Total', currencyFormater.format(taxes), 'pi-thumbs-down-fill', 'cyan', 'Taxable Revenue', currencyFormater.format(accomodationTotal + cleaning))}
                        {statCard('Commission', currencyFormater.format(commission), 'pi-flag', 'yellow')}
                        {statCard('Refunds', currencyFormater.format(refunds), 'pi-credit-card', 'red')}
                        {statCard('Reservations', `${reservations} (${nights} Nights)`, 'pi-calendar-plus', 'blue')}
                    </div>
                    {billingTable}
                </div>
                <div className="col-12 md:col-6 lg:col-4">
                    {getByUnitProgressBars()}
                </div>

            </div>
        </div>
    )

    return (
        <div>
            <div>{getFilters()} </div>
            <div>{dashboard()} </div>
        </div>
    );
}