import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Loader, cleanFormatPhoneNumber, currencyFormater, formatPhoneNumber } from "../../common";
import moment from 'moment-timezone';
import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";

import './ReservationView.css'
import { bookingHotelIdMap } from "../../constants";

export const ReservationView = ({ id }: { id: string | null }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [reservation, setReservation] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);


    const fetchData = async () => {
        if (id === null) return;
        try {
            setIsLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dynamo?id=${id}`);
            const data = await response.json();
            setReservation(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onHide = () => {
        searchParams.delete('reservation');
        setSearchParams(searchParams.toString());
        setIsLoading(true);
    }

    const onShow = () => {
        fetchData();
    }

    const channelFormat = (reservation: any) => {
        switch (reservation.channel) {
            case 'uplisting': return "Uplisiting";
            case 'airbnb_official': return airbnbLink(reservation.external_reservation_id);
            case 'google': return "Google";
            case 'booking_dot_com': return bookingLink(reservation.external_reservation_id, reservation.property_id);
            case 'home_away': return "VRBO";
        }
    } 
   
    const bookingLink = (external_reservation_id: string, property_id: number) => {        
        const bookingLink = `https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/booking.html?hotel_id=${bookingHotelIdMap.get(property_id)}&res_id=${external_reservation_id}`
        return (<a target="_blank" rel="noreferrer" href={bookingLink}>Booking.com</a>)
    }
    
    const airbnbLink = (external_reservation_id: string) => {
        const uplistingLink = `https://www.airbnb.com/reservation/itinerary?code=${external_reservation_id}`
        return (<a target="_blank" rel="noreferrer" href={uplistingLink}>Airbnb</a>)
    }

    const uplistingId = (id: string) => {
        const uplistingLink = `https://app.uplisting.io/calendar/bookings/${id}/summary`
        return (<a target="_blank" rel="noreferrer" href={uplistingLink}>{id}</a>)
    }

    const infoCard = () => {
        return (<>
            <div className="card view-pannel">
                <Card title="Reservation Details">
                    <div className="details cancelled font-bold">{reservation?.reason?.toUpperCase()}</div>
                    <div className="details"><b>Guest Name: </b>{reservation.guest_name}</div>
                    <div className="details"><b>Uplisting ID: </b>{uplistingId(reservation.id)}</div>
                    <div className="details"><b>Number of Guests: </b>{reservation.number_of_guests}</div>
                    <div className="details"><b>Number of Nights: </b>{reservation.number_of_nights}</div>
                    <div className="details"><b>Cabin Number: </b>{reservation.multi_unit_name || reservation.property_name}</div>
                    <div className="details"><b>Arrival Date: </b>{moment(reservation.check_in).format('M/DD/YY')}</div>
                    <div className="details"><b>Departure Date: </b>{moment(reservation.check_out).format('M/DD/YY')}</div>
                    <div className="details"><b>Check-in: </b>{moment(`${reservation.check_in}T${reservation.arrival_time}`).format('h:mm A')}</div>
                    <div className="details"><b>Check-out: </b>{moment(`${reservation.check_out}T${reservation.departure_time}`).format('h:mm A')}</div>
                    <div className="details"><b>Channel: </b>{channelFormat(reservation)}</div>
                    <div className="details"><b>Booked: </b>{moment(reservation.booked_at).format('M/DD/YY hh:mm A')}</div>
                </Card>
            </div>
            <div className="card view-pannel">
            <Card title="Contact Info">
                <div className="details"><b>Phone Number: </b>
                    <a  href={`openphone://message?number=${cleanFormatPhoneNumber(reservation.guest_phone)}&from=9094154611`}>{formatPhoneNumber(reservation.guest_phone)}</a>
                </div>
                <div className="details"><b>Email: </b>{reservation.guest_email}</div>
                {reservation.note && <div className="details"><b>Notes: </b>{reservation.note}</div>}
            </Card>
        </div>
        </>
        )
    };

    const priceCard = () => {
        const grossRevenue = reservation.accomodation_total
                            + reservation.cleaning_fee
                            + reservation.booking_taxes
                            + Number(reservation.other_charges)
                            - reservation.discounts
                            - (reservation.refund_amount || 0);
        const totalPayout = grossRevenue
                            - reservation.commission
                            - reservation.payment_processing_fee;    
        // const tooltipMessage = "Gross Revenue - Commission - Processing Fees - Cleaning Fee - Taxes"

        return (<>
            <div className="card view-pannel">
                <Card title="Reservation Details">
                <Tooltip target=".custom-target-tooltip" />           
                    <div className="flex details">
                        <div className="flex flex-grow-1 font-bold">Accomodation Total: </div>
                        <div className="flex">{currencyFormater.format(reservation.accomodation_total)}</div>
                    </div>
                    <div className="flex details">
                        <div className="flex flex-grow-1 font-bold">Cleaning Fee: </div>
                        <div className="flex">{currencyFormater.format(reservation.cleaning_fee)}</div>
                    </div>
                    <div className="flex details">
                        <div className="flex flex-grow-1 font-bold">Other Charges: </div>
                        <div className="flex">{currencyFormater.format(reservation.other_charges)}</div>
                    </div>
                    <div className="flex details">
                        <div className="flex flex-grow-1 font-bold">Discounts: </div>
                        <div className="flex">{currencyFormater.format(reservation.discounts)}</div>
                    </div>
                    <div className="flex details">
                        <div className="flex flex-grow-1 font-bold">Tax: </div>
                        <div className="flex">{currencyFormater.format(reservation.booking_taxes)}</div>
                    </div>
                    <div className="flex details">
                        <div className="flex flex-grow-1 font-bold">Commission: </div>
                        <div className="flex">({currencyFormater.format(reservation.commission)})</div>
                    </div>
                    <div className="flex details border-bottom">
                        <div className="flex flex-grow-1 font-bold">Processing Fees: </div>
                        <div className="flex">({currencyFormater.format(reservation.payment_processing_fee)})</div>
                    </div>
                    {(reservation.refund_amount) && 
                        <div className="flex details border-bottom">
                            <div className="flex flex-grow-1 font-bold refund">Refund: </div>
                            <div className="flex refund">-{currencyFormater.format(reservation.refund_amount)}</div>
                        </div>
                    }

                    <div className="flex details border-top custom-target-tooltip" data-pr-tooltip={`Guest Paid`} data-pr-position="bottom">
                        <div className="flex flex-grow-1 font-bold ">Gross Revenue: </div>
                        <div className="flex font-bold">{currencyFormater.format(grossRevenue)}</div>
                    </div>
                    <div className="flex details border-top custom-target-tooltip" data-pr-tooltip={`Host Payout`} data-pr-position="bottom">
                        <div className="flex flex-grow-1 font-bold">Total Payout: </div>
                        <div className="flex font-bold">{currencyFormater.format(totalPayout)}</div>
                    </div>
                </Card>
            </div>
            <div className="card">
        </div>
        </>
        )
    };

    const sideBarContent = () => {
        return (
            <>
                {infoCard()}
                {priceCard()}
            </>)
    }

    return (
        <div className="card flex justify-content-center">
            <Sidebar visible={id !== null} position="right" onHide={onHide} onShow={onShow} className="w-full md:w-20rem lg:w-30rem">
                {isLoading ? <Loader /> : sideBarContent()}
            </Sidebar>
        </div>
    )

}