import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { currencyFormater } from '../common';
import { useSearchParams } from "react-router-dom";
import { getFriendlyChannelName } from "../utils/channel";


export const BillingTable = ({ data }: { data: any }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const idTemplate = (res: any) => {
        const onClick = (e: any) => {
            searchParams.set('reservation', res.id);
            setSearchParams(searchParams.toString());
        }
        return <div className="link" onClick={onClick}>{res.id}</div>
    }

    const totalTemplate = (row: any) => {
        const total = 
        Number(row.accomodation_total) +
        Number(row.booking_taxes) +
        Number(row.cleaning_fee) +
        Number(row.commission) +
        Number(row.extra_charges) +
        Number(row.other_charges);
        
        return (currencyFormater.format(total))
    }

    const accomodationTotalTemplate = (row: any) => {
        return (currencyFormater.format(row.accomodation_total))
    }
    
    const bookingTaxesTemplate = (row: any) => {
        return (currencyFormater.format(row.booking_taxes))
    }

    const channelTemplate = (row: any) => {
        return getFriendlyChannelName(row.channel)
    }

    const cleaningFeeTemplate = (row: any) => {
        return (currencyFormater.format(row.cleaning_fee))
    }
    
    const discountTemplate = (row: any) => {
        return (currencyFormater.format(row.discounts))
    }

    const commissionTemplate = (row: any) => {
        return (currencyFormater.format(row.commission))
    }

    const extraChargesTemplate = (row: any) => {
        return (currencyFormater.format(row.extra_charges))
    }
    
    const paymentProcessingFeeTemplate = (row: any) => {
        return (currencyFormater.format(row.payment_processing_fee))
    }

    const refundsTemplate = (row: any) => {
        return (currencyFormater.format(row.refund_amount | 0))
    }
    
    const otherChargesTemplate = (row: any) => {
        return (currencyFormater.format(row.other_charges))
    }


    return (
        <DataTable value={data} scrollable scrollHeight="400px">
            <Column field="id" header="ID" body={idTemplate}></Column>
            <Column field="unit" header="Unit" ></Column>
            <Column field="id" header="Total" body={totalTemplate} ></Column>
            <Column field="accomodation_total" header="Accom. Total" body={accomodationTotalTemplate}></Column>
            <Column field="cleaning_fee" header="Cleaning" body={cleaningFeeTemplate}></Column>
            <Column field="discount" header="Discount" body={discountTemplate}></Column>
            <Column field="booking_taxes" header="Taxes" body={bookingTaxesTemplate}></Column>
            <Column field="commission" header="Commission" body={commissionTemplate}></Column>
            <Column field="extra_charges" header="Extra Charges" body={extraChargesTemplate}></Column>
            <Column field="payment_processing_fee" header="Processing Fee" body={paymentProcessingFeeTemplate}></Column>
            <Column field="other_charges" header="Other Charges" body={otherChargesTemplate}></Column>
            <Column field="refund_amount" header="Refunds" body={refundsTemplate}></Column>
            <Column field="channel" header="Channel" body={channelTemplate}></Column>
        </DataTable>
    )
}
