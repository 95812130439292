import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { currencyFormater } from '../common';
import { Button } from "primereact/button";

export const RefundsTable = ({ data, loading, refresh }: { data: any, loading: boolean, refresh: () => any }) => {
    const tableStyle = () => ({ paddingLeft: '40px', paddingRight: '40px' })

    const idTemplate = (row: any) => {
        const uplistingLink = `https://app.uplisting.io/calendar/bookings/${row.id}/summary`
        return (<a target="_blank" rel="noreferrer" href={uplistingLink}>{row.id}</a>)
    }

    const totalTemplate = (row: any) => {
        const total = Number(row.gross_revenue)
        return (currencyFormater.format(total))
    }

    const updateItem = async (id: number, body: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dynamo`, {
                method: 'POST',
                body: JSON.stringify({ id, ...body })
            })
            await refresh();
            return response.json()
        } catch (err) {
            console.log(err)
        }
    }

    const markRefundedButton = (row: any) => (
        <Button onClick={() => updateItem(row.id, { refund_status: true })}>
            Mark as Refunded
        </Button >
    )

    return (
        <DataTable loading={loading} value={data} tableStyle={tableStyle()}>
            <Column field="id" header="ID" body={idTemplate}></Column>
            <Column field="unit" header="Unit" ></Column>
            <Column field="guest_name" header="Guest Name" ></Column>
            <Column header="Total Paid" body={totalTemplate} ></Column>
            <Column header="Action" body={markRefundedButton} ></Column>
        </DataTable>
    )
}
