import React, { useCallback, useMemo, useState } from 'react';
import { ReservationsTable } from '../../Components/ReservationsTable/ReservationsTable';
import { SFHNames, bigbear_cleaner, bigbear_team, internal_team, kcmo_team } from '../../constants';
import { TabPanel, TabView } from "primereact/tabview"
import { useBookings } from '../../hooks/useBookings';
import { useLocks } from '../../hooks/useLocks';
import './calendar.css'
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { useAuth0 } from '@auth0/auth0-react';

interface FilterOption {
    label: string;
    value: string;
    filter: (x: any) => boolean
}

export function Calendar() {
    const [filterValue, setFilterValue] = useState<string>();

    const { codes } = useLocks();
    const { user } = useAuth0();
    const [activeIndex, setActiveIndex] = useState([...internal_team, ...bigbear_cleaner, ...bigbear_team].includes(user?.email!) ? 0 : 2);
    const { bookings: bigBearBookings, isLoading: isLoadingBigBear } = useBookings();
    const { bookings: walnutV2Bookings, isLoading: loadingWalnutV2 } = useBookings(`${process.env.REACT_APP_BACKEND_URL}/bookings/83511`);
    const { bookings: walnutBookings, isLoading: loadingWalnut } = useBookings(`${process.env.REACT_APP_BACKEND_URL}/bookings/83020`);
    const walnutCombinedBookings = walnutBookings.concat(walnutV2Bookings)

    const today = useMemo(() => {
        let date = new Date();
        date.setHours(date.getHours() - 7);
        return date.toISOString().split('T')[0]
    }, [])

    const filterOptions: FilterOption[] = useMemo(() => {
        return [
            { label: 'Check Ins', value: 'check_in', filter: (x: { check_in: string; }) => { return x.check_in.split('T')[0] === today } },
            { label: 'Check Outs', value: 'check_out', filter: (x: { check_out: string; }) => { return x.check_out.split('T')[0] === today } }
        ]
    }, [today]);

    const filterTable = useCallback((data: any[]) => {
        return filterValue ? data.filter(filterOptions.find((x: any) => x.value === filterValue)!?.filter) : data
    }, [filterValue, filterOptions])

    const filterOptionsTemplate = (option: FilterOption) => {
        return option.label
    }

    const lakefrontCabins = useMemo(() => bigBearBookings?.filter((x: any) => x.unit >= 50), [bigBearBookings])
    const villageCabins = useMemo(() => bigBearBookings?.filter((x: any) => x.unit >= 40 && x.unit <= 49), [bigBearBookings])
    const tulipCabins = useMemo(() => bigBearBookings?.filter((x: any) => x.unit >= 20 && x.unit <= 29), [bigBearBookings])
    const boulderBay = useMemo(() => bigBearBookings.filter((x: any) =>
        !lakefrontCabins.includes(x) && !villageCabins.includes(x) && !tulipCabins.includes(x) &&
        !SFHNames.has(x.unit)
    ), [bigBearBookings, lakefrontCabins, villageCabins, tulipCabins])

    return (
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {[...internal_team, ...bigbear_cleaner, ...bigbear_team].includes(user?.email!) &&
                <TabPanel header={"Wander Collective"}>
                    <div className="right-align">
                        <SelectButton value={filterValue} onChange={(e: SelectButtonChangeEvent) => setFilterValue(e.value)} itemTemplate={filterOptionsTemplate} optionLabel="value" options={filterOptions} />
                    </div>

                    <h2 className='header'>Lakefront</h2>
                    <ReservationsTable data={filterTable(lakefrontCabins)} codes={codes} loading={isLoadingBigBear} />
                    <h2 className='header'>Village</h2>
                    <ReservationsTable data={filterTable(villageCabins)} codes={codes} loading={isLoadingBigBear} />
                    <h2 className='header'>Boulder Bay</h2>
                    <ReservationsTable data={filterTable(boulderBay)} codes={codes} loading={isLoadingBigBear} />
                    <h2 className='header'>Tulip</h2>
                    <ReservationsTable data={filterTable(tulipCabins)} codes={codes} loading={isLoadingBigBear} />
                </TabPanel>}
            {[...kcmo_team, ...internal_team].includes(user?.email!) &&
                <TabPanel header={"3624 Walnut"}>
                    <div className="right-align">
                        <SelectButton value={filterValue} onChange={(e: SelectButtonChangeEvent) => setFilterValue(e.value)} itemTemplate={filterOptionsTemplate} optionLabel="value" options={filterOptions} />
                    </div>
                    <h2 className='header'>3624 Walnut</h2>
                    <ReservationsTable
                        data={filterTable(walnutCombinedBookings)}
                        loading={loadingWalnut || loadingWalnutV2}
                        multiFamily={false} />
                </TabPanel>
            }
        </TabView>
    );
}
