import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import {  useMemo, useState } from "react"
import { TabPanel, TabView } from "primereact/tabview"
import { useLocks } from "../hooks/useLocks"
import { ProgressSpinner } from "primereact/progressspinner"
import './Locks.css'
import { Skeleton } from "primereact/skeleton"

export const Locks = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { codes, isLoading } = useLocks()

    const deleteExpired = async () => {
        for (let i = 0; i < expiredLocks.length; i++) {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/locks?action=delete`, {
                method: 'POST',
                body: JSON.stringify({
                    lockId: expiredLocks[i].lockId,
                    keyboardPwdId: expiredLocks[i].keyboardPwdId
                })
            })
        }
    }

    const codesMapped = useMemo(() => {
        const map: any = {}
        codes.forEach((code: any) => {
            const exisitingValues = map[code.cabinNum];
            const newValue = exisitingValues ? [...exisitingValues, code] : [code]
            map[code.cabinNum] = newValue
        })
        return map;
    }, [codes])

    const permanentLocks = useMemo(() =>
        codes?.filter((x: any) => x.isExpired && x.endDate.includes("1969-12-31")),
        [codes])

    const expiredLocks = useMemo(() =>
        codes?.filter((x: any) => x.isExpired && !x.endDate.includes("1969-12-31")),
        [codes])

    if (isLoading) return <div className="vertical-center"><ProgressSpinner /></div>

    return (
        <div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {Object.keys(codesMapped).sort().map((key) => {
                    const codes = codesMapped[key].filter((x: any) => !x.isExpired)
                    if (codes.length <= 0) {
                        return <Skeleton />
                    }
                    const header = codes[0]?.cabinNum
                    return (
                        <TabPanel header={header} key={key}>
                            <DataTable
                                loading={isLoading}
                                value={codes}
                                scrollable>
                                <Column sortable field="keyboardPwd" header="Code"></Column>
                                <Column sortable style={{ width: "300px" }} field="keyboardPwdName" header="Name"></Column>
                                <Column sortable field="startDate" header="Start Date"></Column>
                                <Column sortable field="endDate" header="End Date"></Column>
                            </DataTable>
                        </TabPanel>
                    )
                })}
                {
                    !isLoading && <TabPanel header={`Expired (${expiredLocks.length})`}>
                        <Button style={{ margin: '10px' }} label='Delete Expired Codes' onClick={deleteExpired} />
                        <DataTable
                            loading={isLoading}
                            value={expiredLocks}
                            scrollable>
                            <Column sortable field="cabinNum" header="Cabin #"></Column>
                            <Column sortable field="keyboardPwd" header="Code"></Column>
                            <Column sortable style={{ width: "300px" }} field="keyboardPwdName" header="Name"></Column>
                            <Column sortable field="startDate" header="Start Date"></Column>
                            <Column sortable field="endDate" header="End Date"></Column>
                        </DataTable>
                    </TabPanel>
                }

                {
                    !isLoading && <TabPanel header={`Permanent (${permanentLocks.length})`}>
                        <DataTable
                            loading={isLoading}
                            value={permanentLocks}
                            scrollable>
                            <Column sortable field="cabinNum" header="Cabin #"></Column>
                            <Column sortable field="keyboardPwd" header="Code"></Column>
                            <Column sortable style={{ width: "300px" }} field="keyboardPwdName" header="Name"></Column>
                            <Column sortable field="startDate" header="Start Date"></Column>
                            <Column sortable field="endDate" header="End Date"></Column>
                        </DataTable>
                    </TabPanel>
                }

            </TabView>
        </div >
    )
}