
import { useBookings } from '../../hooks/useBookings';
import { RefundsTable } from '../../Components/RefundsTable';

export function RefundTracker() {
    const { bookings, isLoading, getBookings } = useBookings(
        `${process.env.REACT_APP_BACKEND_URL}/bookings?forRefunds=true&from=2023-11-01&to=2024-12-31`
    )

    return (
        <div className='App'>
            <h2 className='header'>Refund Tracker</h2>
            <RefundsTable loading={isLoading} data={bookings} refresh={getBookings} />
        </div >
    );
}